

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Poppins', sans-serif;
  text-decoration: none;
}

body{
  list-style-type: none;
}
 
 @tailwind components;
@tailwind utilities;
.cardSection{
  @apply p-6 rounded-lg shadow-md flex flex-col-reverse md:flex-row-reverse;
  background: linear-gradient(#0F2247, #5E2946);
  transition: background 0.8s ease-in-out 1.5s, transform 0.3s ease-in-out;
}
.cardSection:hover {
  background: linear-gradient(#5E2946, #0F2247);
} 
