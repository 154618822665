.digital_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 30px;
}
.digital_heading {
  text-align: center;
}
.digital_head {
  font-size: 2.5rem;
  color: #2e3192;
}
.digital_para {
  padding-top: 2px;
  width: 50%;
  margin: auto;
  color: #777;
}
.digital_card_container {
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}
.digital_card {
}
.digital_card_top {
  height: 160px;
  width: 260px;
  background-color: #284cba;
  border-radius: 225px 225px 0px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}
.digital_card_top_head {
  font-size: 2rem;
  margin-top: 20px;
}
.digital_card_mid {
  width: 260px;
  height: 300px;
  background-color: rgb(248, 248, 248);
  padding: 20px 30px;
}
.digital_card_bottom {
  width: 260px;
  height: 20px;
  background-color: #284cba;
  border-radius: 0px 0px 10px 10px;
}
.digital_card_points {
  font-size: 0.98rem;
  font-weight: 400;
  color: #222222;
}
.digital_card_points::marker {
  color: #ff6247;
}
.arrow {
  margin-right: 5px;
}
.bg_color {
  background-color: #f3a83f;
}
.bg_color_1 {
  background-color: #1c9ebb;
}
@media screen and (max-width: 1024px) {
  .digital_para {
    width: 80%;
  }
}
@media screen and (max-width: 768px) {
  .digital_para {
    width: 100%;
  }
  .digital_card_container {
    align-items: center;
    justify-content: center;
  }
  .digital_para {
    font-size: 0.9rem;
  }
  .digital_head {
    font-size: 2rem;
  }
}
